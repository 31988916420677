import React from 'react';
import styled from 'styled-components';

import { H1, H2, H3, H4, H5, H6, Paragraph } from '../styles/commonStyles';

const Container = styled.section`
  height: 100%;
  width: 100%;
  padding-top: 12rem;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const text = {
  header: `Dicta ipsam`,
  paragraph: `Dicta ipsam assumenda ea dolorem exercitationem iusto magnam quas. Ut animi nemo molestiae rem
  eius facere. Natus non dolorum modi accusantium tempora ipsum. Corporis cumque et dolores
  rerum et. Veritatis odit tempora voluptates voluptatem. Quod dignissimos odit consequatur
  perferendis.`,
};
const Typography = () => (
  <Container>
    <H1>{text.header}</H1>
    <H2>{text.header}</H2>
    <H3>{text.header}</H3>
    <H4>{text.header}</H4>
    <H5>{text.header}</H5>
    <H6>{text.header}</H6>
    <hr />
    <Paragraph>{text.paragraph}</Paragraph>
    <hr />
    <H5>{text.header}</H5>
    <Paragraph>{text.paragraph}</Paragraph>
  </Container>
);

export default Typography;
